import React from "react";
import "./MainOffers.css";
import OffersPanel from "./offerspanel/OffersPanel";

function MainOffers() {
  return (
    <div className="main-container">
      <div className="side-column"></div>

      <div className="content-column">
        <OffersPanel />
      </div>

      <div className="side-column"></div>
    </div>
  );
}

export default MainOffers;
