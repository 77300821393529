import axios from "axios";

const BROWSER_URL = "https://browser-app.danka.p3.tiktalik.io/api/v1";
const PANEL_URL = "https://panel-app.danka.p3.tiktalik.io/api/v1";

// const BROWSER_URL = "http://37.233.99.31:8081/api/v1";
// const PANEL_URL = "http://37.233.99.33:8082/api/v1";


export const fetchOffers = (page, size, categoryId, regionId) => {
  const categoryParam = categoryId && categoryId !== "ALL" ? `&categoryId=${categoryId}` : "";
  const locationParam = regionId && regionId !== "ALL" ? `&regionId=${regionId}` : "";

  return axios.get(`${BROWSER_URL}/offers?size=${size}&page=${page-1}${categoryParam}${locationParam}`);
};

export const fetchDictionaries = () => {
  return Promise.all([
    axios.get(`${BROWSER_URL}/dictionaries/categories`),
    axios.get(`${BROWSER_URL}/dictionaries/locations`),
  ]);
};

export const fetchOfferDetails = (itemId) => {
    return axios.get(`${BROWSER_URL}/offers/${itemId}`);
};

export const fetchToken = (username, password) => { 
    return fetch(`${PANEL_URL}/auth/token`, 
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password }),
        }
    )
};

export const registerUser = (username, password) => { 
  return fetch(`${PANEL_URL}/auth/register`, 
      {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password }),
      }
  )
};

// const response = await fetch("http://localhost:8082/api/v1/auth/register", {
//   method: "POST",
//   headers: { "Content-Type": "application/json" },
//   body: JSON.stringify({ username, password }),
// });