import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "./secur/AuthContext";
import PrivateRoute from "./secur/PrivateRoute";
import HomePage from "./HomePage";
import Offers from "./Offers";

function DebugRoutes() {
  const location = useLocation();
  console.log("Current path:", location.pathname);
  return null;
}

function App() {
  return (
    <AuthProvider>
      <Router basename="/">
        <DebugRoutes />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/my-offers" element={
              <PrivateRoute>
                <Offers />
              </PrivateRoute>
          } />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
