import React from "react";
import "./Title.css";

const Title = () => {
    return (
        <section className="title">Offly - Zrealizuj usługę.</section>
    );
};

export default Title;
