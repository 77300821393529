import React from "react";
import "./ModalList.css";

function ModalList({ title, items, closeModal, onSelect }) {
  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{title}</h2>
        <ul className="modal-list">
          {items.map((item, index) => (
            <li key={index} className="modal-list-item" onClick={() => onSelect(item)}>
                {item.value}
            </li>
          ))}
        </ul>
        <button className="modal-close-btn" onClick={closeModal}>Zamknij</button>
      </div>
    </div>
  );
}

export default ModalList;
