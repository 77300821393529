import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./../secur/AuthContext";
import { fetchToken, registerUser } from "./../api/api";
import "./Header.css";

function Header() {

  const { username, logout, isAuthenticated, login } = useContext(AuthContext);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  const handleLogin = async (username, password) => {
    try {
      const response = await fetchToken(username, password);

      if (response.ok) {
        const { token } = await response.text();
        login(token, username);
        setShowLoginModal(false);
        setErrorMessage(null);
        window.location.reload(); 
      } else {
        setErrorMessage("Nieprawidłowe dane logowania.");
      }
    } catch (error) {
      console.error("Błąd logowania:", error);
      setErrorMessage("Wystąpił problem podczas logowania.");
    }
  };


  const handleSignUp = async (username, password, password2) => {
    if (password !== password2) {
      setErrorMessage("Hasła się nie zgadzają.");
      return;
    }

    try {
      const response = await registerUser(username, password);

      if (response.ok) {
        setShowSignUpModal(false);
        setErrorMessage(null);
        alert("Rejestracja zakończona sukcesem. Możesz się teraz zalogować.");
      } else {
        setErrorMessage("Rejestracja nie powiodła się.");
      }
    } catch (error) {
      console.error("Błąd rejestracji:", error);
      setErrorMessage("Wystąpił problem podczas rejestracji.");
    }
  };


  return (
    <header className="header">
      <div>
        <Link to="/" className="header-logo">Offly.pl</Link>
      </div>
      
      <div>
        {isAuthenticated ? (
          <>
            Witaj, {username}! &nbsp; <button onClick={logout}>Logout</button>
          </>
        ) : (
          <>
            <button onClick={() => setShowLoginModal(true)}>Login</button>
            &nbsp;
            <button onClick={() => setShowSignUpModal(true)}>SignUp</button>
          </>
        )}
      </div>
      
      {showLoginModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Login</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <input type="text" placeholder="Username" id="username" />
            <input type="password" placeholder="Password" id="password" />
            <button 
              onClick={() => {
                const username = document.getElementById("username").value;
                const password = document.getElementById("password").value;
                handleLogin(username, password);
              }}
            >
              Zaloguj
            </button>
            <button onClick={() => {
                setShowLoginModal(false);
                setErrorMessage(null);
            }}>Anuluj</button>
          </div>
        </div>
      )}  


      {showSignUpModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>SignUp</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <input type="text" placeholder="Username (email)" id="signup-username" />
            <input type="password" placeholder="Password" id="signup-password" />
            <input type="password" placeholder="Repeat Password" id="signup-password2" />
            <button
              onClick={() => {
                const username = document.getElementById("signup-username").value;
                const password = document.getElementById("signup-password").value;
                const password2 = document.getElementById("signup-password2").value;
                handleSignUp(username, password, password2);
              }}
            >
              Zarejestruj
            </button>
            <button
              onClick={() => {
                setShowSignUpModal(false);
                setErrorMessage(null);
              }}
            >
              Anuluj
            </button>
          </div>
        </div>
      )}


    </header>
  );
};

export default Header;
