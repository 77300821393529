import React from "react";
import { Link } from "react-router-dom";
import "./MainHome.css";

function MainHome() {
  return (
    <div>

    <div style={{ textAlign: "center" }}>
        <h1>Przeglądaj oferty, klikając poniższy przycisk.</h1>
        {/* <Link to="/offers">
          <button className="main-btn">Przeglądaj oferty</button>
        </Link> */}
      </div>


      <div className="service-grid">
      <Link to="/offers" className="service-item">
          💻 <span>IT</span>
        </Link>
        <Link to="/offers" className="service-item">
          🏗️ <span>Budowlanka</span>
        </Link>
        <Link to="/offers" className="service-item">
          🔧 <span>Mechanik</span>
        </Link>
        <Link to="/offers" className="service-item">
          📚 <span>Nauczyciel</span>
        </Link>
        <Link to="/offers" className="service-item">
          🛠️ <span>Naprawa</span>
        </Link>
        <Link to="/offers" className="service-item">
          🏡 <span>Ogrodnictwo</span>
        </Link>
        <Link to="/offers" className="service-item">
          🚚 <span>Transport</span>
        </Link>
        <Link to="/offers" className="service-item">
          🍳 <span>Gotowanie</span>
        </Link>
        <Link to="/offers" className="service-item">
          ✍️ <span>Tłumaczenia</span>
        </Link>
        <Link to="/offers" className="service-item">
          🧹 <span>Sprzątanie</span>
        </Link>
        <Link to="/offers" className="service-item">
          🧵 <span>Krawiectwo</span>
        </Link>
        <Link to="/offers" className="service-item">
          🩺 <span>Medycyna</span>
        </Link>
        <Link to="/offers" className="service-item">
          🎨 <span>Artysta</span>
        </Link>
        <Link to="/offers" className="service-item">
          🛒 <span>Zakupy</span>
        </Link>
        <Link to="/offers" className="service-item">
          📦 <span>Przeprowadzki</span>
        </Link>
        <Link to="/offers" className="service-item">
          ⚙️ <span>Usługi techniczne</span>
        </Link>
      </div>

      <div style={{ textAlign: "center", padding: "20px"}}>
        <Link to="/offers">
          <button className="main-btn">Przeglądaj oferty</button>
        </Link>
      </div>


    </div>
  );
}

export default MainHome;
