import React from "react";
import "./Paging.css";

function Paging({ currentPage, totalPages, goToPreviousPage, goToNextPage }) {
  return (
    <div className="pagination">
      <button onClick={goToPreviousPage} disabled={currentPage === 1}>Poprzednia</button>
      <span>Strona: {currentPage}</span>
      <button onClick={goToNextPage} disabled={currentPage === totalPages}>Następna</button>
    </div>
  );
}

export default Paging;
