import React from "react";
import './App.css';
import Header from './header/Header';
import Title from './title/Title';
import MainOffers from './main/MainOffers';
import Footer from './footer/Footer';

function Offers() {
  return (
    <div className="App">

      <Header />

      <Title />

      <MainOffers />

      <Footer />

    </div>
  );
}

export default Offers;
