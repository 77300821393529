import React from "react";
import { format } from "date-fns";
import "./OffersDetailsShort.css";

function OffersDetailsShort({ item, openModal }) {
  return (
    <div key={item.id} className="list-item" onClick={() => openModal(item.id)}>
      <div className="list-item-header">
        <h3>{item.title}</h3>
        <span className="list-item-meta">{item.categoryId} | {item.regionId}</span>
      </div>
      <div className="list-item-date">{format(new Date(item.created), "dd.MM.yyyy HH:mm")}</div>
      {/* <div className="list-item-date">{item.userName}</div> */}
      <p className="list-item-description">{item.description}</p>
    </div>
  );
}

export default OffersDetailsShort;
