import React, { useState, useEffect } from "react";
import Filter from "./filter/Filter";
import ModalList from "./filter/ModalList";
import OffersDetailsShort from './offersdetails/OffersDetailsShort';
import OffersDetailsFull from './offersdetails/OffersDetailsFull';
import Paging from './paging/Paging';
import { fetchOffers, fetchDictionaries, fetchOfferDetails } from "./../../api/api";
import './OffersPanel.css';



function OffersPanel() {

  const [items, setItems] = useState({});

  const [categories, setCategories] = useState([]); 
  const [locations, setLocations] = useState([]);

  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState({ key: "ALL", value: "Wszystkie" }); 
  const [selectedLocation, setSelectedLocation] = useState({ key: "ALL", value: "Wszystkie" }); 

  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 

  const pageSize = 8;


  useEffect(() => {
    fetchOffers(currentPage, pageSize, selectedCategory.key, selectedLocation.key)
      .then(response => {
        setItems(response.data);
        setTotalPages(response.data.totalPages); 
      })
      .catch(error => {
        console.error("Błąd przy pobieraniu danych:", error);
      });
  }, [currentPage, selectedCategory, selectedLocation]);


  useEffect(() => {
    fetchDictionaries()
      .then(([categoriesResponse, locationsResponse]) => {
        const fetchedCategories = categoriesResponse.data.map(category => ({
          key: category.key,
          value: category.value,
        }));
        const fetchedLocations = locationsResponse.data.map(location => ({
          key: location.key,
          value: location.value,
        }));
        setCategories([{ key: "ALL", value: "Wszystkie" }, ...fetchedCategories]); 
        setLocations([{ key: "ALL", value: "Wszystkie" }, ...fetchedLocations]);
      })
      .catch(error => {
        console.error("Błąd przy pobieraniu kategorii/locations:", error);
      });
  }, []);


  const openModal = async (itemId) => {
    const response = await fetchOfferDetails(itemId);
    const data = await response.data;
    setSelectedItem(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const openCategoryModal = () => setCategoryModalOpen(true);
  const closeCategoryModal = () => setCategoryModalOpen(false);

  const openLocationModal = () => setLocationModalOpen(true);
  const closeLocationModal = () => setLocationModalOpen(false);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category); 
    setCurrentPage(1); 
    closeCategoryModal();
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location); 
    setCurrentPage(1); 
    closeLocationModal();
  };


  return (
    <div className="main-content">

      <Filter selectedCategory={selectedCategory} selectedLocation={selectedLocation} openCategoryModal={openCategoryModal} openLocationModal={openLocationModal} />

      <div className="list">
        { items.content ? items.content.map((item) => (

          <OffersDetailsShort key={item.id} item={item} openModal={openModal} />

        )) : <div>Loading....</div> }
      </div>

      {isModalOpen && <OffersDetailsFull selectedItem={selectedItem} closeModal={closeModal} />}

      {isCategoryModalOpen && (
        <ModalList 
          title="Kategorie" 
          items={categories.map(cat => cat)}
          closeModal={closeCategoryModal} 
          onSelect={handleCategorySelect}
        />
      )}

      {isLocationModalOpen && (
        <ModalList 
          title="Lokalizacje" 
          items={locations.map(loc => loc)}
          closeModal={closeLocationModal} 
          onSelect={handleLocationSelect}
        />
      )}

      <Paging currentPage={currentPage} totalPages={totalPages} goToPreviousPage={goToPreviousPage} goToNextPage={goToNextPage} />

    </div>
  );

};
  
export default OffersPanel;