import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    
  const [token, setToken] = useState(localStorage.getItem("jwt") || null);
  const [username, setUsername] = useState(localStorage.getItem("username") || null);

  const login = (token, username) => {
    localStorage.setItem("jwt", token);
    localStorage.setItem("username", username);
    setToken(token);
    setUsername(username);
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    setToken(null);
    setUsername(null);
  };

  return (
    <AuthContext.Provider value={{ token, username, login, logout, isAuthenticated: !!token }}>
      {children}
    </AuthContext.Provider>
  );
}
