import React from "react";
import "./App.css";
import Header from "./header/Header";
import Title from "./title/Title";
import MainHome from "./main/MainHome";
import Footer from "./footer/Footer";

function HomePage() {
  return (
    <div className="App">

      <Header />

      <Title />

      <MainHome />

      <Footer />

    </div>
  );
}

export default HomePage;
