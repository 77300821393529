import React from "react";
import "./Filter.css";

function Filter({ selectedCategory, selectedLocation, openCategoryModal, openLocationModal }) {
  return (
    <div className="opheader">
      <div className="filter">
        <span className="filter-item" onClick={openCategoryModal}>
          Kategorie: <strong>{selectedCategory.value}</strong>
        </span>
        <span className="filter-item" onClick={openLocationModal}>
          Lokalizacja: <strong>{selectedLocation.value}</strong>
        </span>
      </div>
    </div>
  );
}

export default Filter;
