import React from "react";
import "./OffersDetailsFull.css";

function OffersDetailsFull({ selectedItem, closeModal }) {
  return (
    <div className="modal-overlay-full" onClick={closeModal}>
      <div className="modal-content-full" onClick={(e) => e.stopPropagation()}>

        <button className="close-button" onClick={closeModal}>Close</button>
        
        <h2>{selectedItem.title}</h2>
        
        <div className="details-grid">
          <div className="details-left">
            <div><strong>Utworzone:</strong> {selectedItem.created}</div>
            <div><strong>Username:</strong> {selectedItem.userName}</div>
          </div>
          <div className="details-right">
            <div><strong>Kategoria:</strong> {selectedItem.categoryId}</div>
            <div><strong>Region:</strong> {selectedItem.regionId}</div>
          </div>
        </div>
        
        <p className="description">{selectedItem.description}</p>

      </div>
    </div>
  );
}

export default OffersDetailsFull;
